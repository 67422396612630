<template style="background-color:white">
  

  <div v-if="page_loading_full">
    <loading :page_loading_full="page_loading_full" />
  </div>
  <div v-else  style="min-height: 100vh;max-width: 100%; background-color: white;" class="mt-0" align="center">

    <v-card :elevation="0" width="793">
      <v-card-title>
        <v-row>
          <v-col cols="6" class="text-left">
            ใบส่งสินค้า
          </v-col>
          <v-col cols="6" class="text-right">
            เลขที่ <span class="fn-15 pt-1"> {{Ie}} </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>

        <v-data-table
          :headers="header"
          :items="dataset"
          :items-per-page="9999999999"
          hide-default-footer
          class="packhai-border-table"
        
        >
          <template v-slot:item.no="{ item }">
            {{ formatMoney(dataset.indexOf(item)+1) }}
          </template>
       
        </v-data-table>

        <v-row class="mt-10">
          <v-col cols="3">
            ผู้รับสินค้า : .....................
          </v-col>
          <v-col cols="3">
            วันที่เวลา : ......................
          </v-col>
          <v-col cols="3">
            เบอร์โทร : ......................
          </v-col>
          <v-col cols="3">
            เลขทะเบียน : ..................
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { branchService_dotnet } from '@/website/global'
  import { AlertError } from '@/website/global_alert'
  import { formatMoney, GenDN, ChangeIE, GenPA } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading_full: true,
      loading: false,
      Ie: null,
      dataset: [],
      header: [
        { text: 'No',align: 'center',sortable: false,value: 'no', width: '10%' },
        { text: 'TrackingNo',align: 'center',sortable: false,value: 'trackingNo', width: '30%'},
        { text: 'ออเดอร์',align: 'center',sortable: false,value: 'orderMasterId', width: '30%'},
        { text: 'เลขอ้างอิง',align: 'center',sortable: false,value: 'referenceNumber', width: '30%'},
      ],
    }),
    async created () {
      this.Ie = this.GenDN(atob(this.$route.query.id))
      await this.SearchData()
      this.page_loading_full = false
      setTimeout(function(){ print() }, 500);
    },
    methods: {
      async SearchData(){
        var masterId = parseInt(this.Ie.substring(2))
        let response = await axios.post(branchService_dotnet+'Branch/get-delivery-note-detail', {
          "masterId": masterId,
          "isDesc":false 
        }, {headers: header_token})
        if (response.status==200){
          this.dataset = response.data.outboundItem
        } else {
          this.AlertError()
        }
      },
      formatMoney,
      GenDN,
      ChangeIE,
      GenPA,
      AlertError,
    },
  }
</script>
<style  >
  td,th{
  height: 35px !important;
}
</style>
